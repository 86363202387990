// components/images/logo-short.tsx

import Link from "next/link";

interface LogoProps {
  size?: number;
  className?: string;
}

/**
 * Short Logo component that renders an SVG logo with a link to the homepage
 * @param size - The desired height of the logo in pixels (default: 30)
 * @param className - Additional CSS classes to apply to the link wrapper
 */
export default function LogoShort({ size = 30, className = "" }: LogoProps) {
  // Original dimensions from the SVG file
  const originalWidth = 179;
  const originalHeight = 179;

  // Calculate scaling while maintaining aspect ratio
  const scale = size / originalHeight; // Scale based on height to maintain aspect ratio
  const scaledWidth = Math.round(originalWidth * scale);
  const scaledHeight = size;

  return (
    <Link href="/" className={`inline-flex ${className}`} aria-label="Home">
      <img
        src="/images/Logo-Transparent.svg"
        width={scaledWidth}
        height={scaledHeight}
        alt="Guideful Icon"
        style={{ minWidth: `${scaledWidth}px` }}
      />
    </Link>
  );
}
