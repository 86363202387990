import Link from "next/link";

interface LogoWortmarkeProps {
  size?: number;
  className?: string;
}

/**
 * LogoWortmarke component that renders the wordmark SVG logo with a link to the homepage
 * @param size - Desired height of the logo in pixels (Default: 30)
 * @param className - Additional CSS classes for the Link wrapper
 */
export default function LogoWortmarke({ size = 30, className = "" }: LogoWortmarkeProps) {
  // Original dimensions from the SVG file (adjust these if your new SVG has different dimensions)
  const originalWidth = 207;
  const originalHeight = 64;

  // Calculate scaling while maintaining aspect ratio
  const scale = size / originalHeight;
  const scaledWidth = Math.round(originalWidth * scale);
  const scaledHeight = size;

  return (
    <Link href="/" className={`inline-flex ${className}`} aria-label="Home">
      <img
        src="/images/Logo-Wortmarke-Transparent.svg"
        width={scaledWidth}
        height={scaledHeight}
        alt="Guideful Wordmark Logo"
        style={{ minWidth: `${scaledWidth}px` }}
      />
    </Link>
  );
}