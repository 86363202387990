import Link from 'next/link'
import {Dialog, DialogPanel, Transition, TransitionChild} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {useSearch} from '@/app/hooks/useSearch'
import { generateGuideSlug } from '@/app/lib/slugUtil';
import {MagnifyingGlassIcon, ShoppingBagIcon, TagIcon, UserCircleIcon} from '@heroicons/react/24/outline';


interface SearchModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export default function SearchModal({
                                      isOpen,
                                      setIsOpen
                                    }: SearchModalProps) {
  const {query, setQuery, results, isLoading, error, searchHandler} = useSearch();
  const [recentSearches, setRecentSearches] = useState<string[]>([])
  const [recentPages, setRecentPages] = useState<{ title: string, path: string }[]>([])


  const saveRecentSearch = (searchTerm: string) => {
    if (!searchTerm.trim()) return;

    // Get existing searches
    const existingSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');

    // Remove the search term if it already exists to avoid duplicates
    const filteredSearches = existingSearches.filter((search: string) => search !== searchTerm);

    // Add new search term to the beginning
    const newSearches = [searchTerm, ...filteredSearches].slice(0, 5); // Keep only last 5 searches

    // Save back to localStorage
    localStorage.setItem('recentSearches', JSON.stringify(newSearches));

    // Update state
    setRecentSearches(newSearches);
  };

  useEffect(() => {
    // Simulating fetching recent searches from local storage
    const storedSearches = localStorage.getItem('recentSearches')
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches))
    }

    // Simulating fetching recent pages from local storage
    const storedPages = localStorage.getItem('recentPages')
    if (storedPages) {
      setRecentPages(JSON.parse(storedPages))
    }
  }, [])

  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add('modal-open');
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    } else {
      body.classList.remove('modal-open');
      body.style.removeProperty('--scrollbar-width');
    }

    return () => {
      body.classList.remove('modal-open');
      body.style.removeProperty('--scrollbar-width');
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      searchHandler();
    }
  }, [isOpen, searchHandler]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-30 transition-opacity"/>
        </Transition.Child>
        <TransitionChild
          as="div"
          className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
          enter="transition ease-in-out duration-200"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-4"
        >
          <DialogPanel
            className="bg-white dark:bg-gray-800 border border-transparent dark:border-gray-700/60 overflow-auto max-w-2xl w-full max-h-full rounded-lg shadow-lg">
            {/* Search form */}
            <form className="border-b border-gray-200 dark:border-gray-700/60" onSubmit={(e) => e.preventDefault()}>
              <div className="relative">
                <label htmlFor="search-modal" className="sr-only">Search</label>
                <input
                  id="search-modal"
                  className="w-full dark:text-gray-300 bg-white dark:bg-gray-800 border-0 focus:ring-transparent placeholder-gray-400 dark:placeholder-gray-500 appearance-none py-3 pl-10 pr-4"
                  type="search"
                  placeholder="Suche…"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    if (!e.target.value) {
                      // When search is cleared, refresh recent searches
                      const storedSearches = localStorage.getItem('recentSearches');
                      if (storedSearches) {
                        setRecentSearches(JSON.parse(storedSearches));
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && query.trim()) {
                      saveRecentSearch(query.trim());
                    }
                  }}
                />
                <div className="absolute inset-0 flex items-center justify-center right-auto group">
                  <MagnifyingGlassIcon
                    className="h-4 w-4 text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-400 ml-4 mr-2"/>
                </div>
              </div>
            </form>
            <div className="py-4 px-2">
              {isLoading && <p className="text-center">Laden...</p>}
              {error && <p className="text-red-500 text-center">{error}</p>}
              {results.length > 0 ? (
                <ul className="text-sm">
                  {results.map((result) => (
                    <li key={result.id}>
                      <Link
                        className="flex items-center p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700/20 rounded-lg group"
                        href={result.resultType === 'GUIDE'
                          ? `/guide/${generateGuideSlug(`${result.guideGivenName} ${result.guideFamilyName}`, result.guideId)}`
                          : `/product/${result.productId}`
                        }
                      >
                        {result.resultType === 'GUIDE' ? (
                          <UserCircleIcon className="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-400 mr-3"/>
                        ) : (
                          <ShoppingBagIcon className="h-5 w-5 shrink-0 text-purple-500 dark:text-purple-400 mr-3"/>
                        )}
                        <div className="flex-grow">
                          <div className="flex items-center">
                            <span className="font-medium">
                              {result.resultType === 'GUIDE'
                                ? `${result.guideGivenName} ${result.guideFamilyName}`
                                : result.productName}
                            </span>
                            <span className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                              result.resultType === 'GUIDE'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
                                : 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
                            }`}>
                            {result.resultType === 'GUIDE' ? 'Guide' : 'Angebot'}
                          </span>
                          </div>
                          {result.resultType === 'PRODUCT' && (
                            <>
                              {result.productDescription && (
                                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-1">
                                  {result.productDescription}
                                </p>
                              )}
                              {result.categoryNames && result.categoryNames.length > 0 && (
                                <div className="flex items-center mt-1">
                                  <TagIcon className="h-3 w-3 text-gray-400 mr-1"/>
                                  <span className="text-xs text-gray-500">
                                    {result.categoryNames.join(', ')}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : query && !isLoading && results.length === 0 && (
                <p className="text-center">Keine Ergebnisse gefunden</p>
              )}

              {/* Only show recent searches when there's no active query */}
              {!query && (recentSearches.length > 0 || recentPages.length > 0) && (
                <>
                  {/* Recent searches */}
                  {recentSearches.length > 0 && (
                    <div className="mb-3 last:mb-0">
                      <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase px-2 mb-2">
                        Letzte Suchen
                      </div>
                      <ul className="text-sm">
                        {recentSearches.map((search, index) => (
                          <li key={index}>
                            <button
                              className="flex items-center w-full p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700/20 rounded-lg"
                              onClick={() => setQuery(search)}
                            >
                              <svg className="shrink-0 fill-current text-gray-400 dark:text-gray-500 mr-3" width="16"
                                   height="16" viewBox="0 0 16 16">
                                <path
                                  d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z"/>
                              </svg>
                              <span>{search}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {/* Recent pages */}
                  {recentPages.length > 0 && (
                    <div className="mb-3 last:mb-0">
                      <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase px-2 mb-2">
                        Kürzlich besuchte Seiten
                      </div>
                      <ul className="text-sm">
                        {recentPages.map((page, index) => (
                          <li key={index}>
                            <Link
                              className="flex items-center p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700/20 rounded-lg"
                              href={page.path}
                            >
                              <svg className="shrink-0 fill-current text-gray-400 dark:text-gray-500 mr-3" width="16"
                                   height="16" viewBox="0 0 16 16">
                                <path
                                  d="M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h8l5-5V1c0-.6-.4-1-1-1zM3 2h10v8H9v4H3V2z"/>
                              </svg>
                              <span>{page.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}